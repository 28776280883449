.treats-container p {
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0px;
  line-height: 21px;
}

.treats-container table {
  margin-bottom: 20px;
}

.treats-container table tr {
  border-bottom-width: 0px;
}

.treats-container table th {
  color: rgb(129, 188, 0);
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 22px;  
  text-align: center;
}

.treats-container table td {
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  line-height: 55px; 
  padding: 5px 0 5px 0; 
}

.treats-container table tr:nth-child(odd) td {
  background: rgb(245, 245, 245);
  border-radius: 0px;
}