body {
  margin: 0;
  font-family: "proxima-nova";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  text-align: center;
}

.app-header {
  
}

.app-body {
  background-color: #FFF;
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

/* Forms */
input[type=text].rounded-input:not(.browser-default),
input[type=number].rounded-input:not(.browser-default) {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgb(83, 88, 90);
  height: 48px;
  margin-top: 23px;
  text-indent: 26px;
}

input[type=text].rounded-input:not(.browser-default):focus,
input[type=number].rounded-input:not(.browser-default):focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
  box-shadow: none !important;
}

input[type=text].rounded-input:not(.browser-default)::placeholder,
input[type=number].rounded-input:not(.browser-default)::placeholder {
  color: rgb(193, 198, 200);
  font-family: "proxima-nova";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.1px;
}

/* remove shadows on iOS */
textarea, input[type="text"] {
  -webkit-appearance: none;
}

/* checked box */
input[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid rgb(129, 188, 0);
  background-color: rgb(129, 188, 0);
}

/* unchecked box */
[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
  border: 2px solid rgb(193, 198, 200)
}

.checkbox-label {
  color: rgb(102, 102, 102);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
}

p.directions {
  text-transform: uppercase;
}

p.label-description,
p.label-description.col {
  color: rgb(50, 51, 53);
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 14px;
  padding-left: 40px;
  margin-bottom: 0;
}

.oops-container {
  margin-top: 31px;
}

.oops-container .green-label {
  color: rgb(129, 188, 0);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 13px;
  text-align: left;
  margin-bottom: 0;
}

.oops-container p {
  color: rgb(102, 102, 102);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: left;
}